/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/atx-resized.jpg";

// JPAlert, bs hacked for simplicity
import JPAlert from "components/JPAlert";

// Util
import { validateEmail, delay } from "lib/util";

function ContactUs() {
  const [warningText, setWarningText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [displaySuccess, setDisplaySuccess] = useState(null);

  const handleClick = (currentTarget) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/about-us",
            label: "home",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                EJSC Consulting LLC <br />
                PO Box 202562 <br />
                Austin, TX 78720
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={3}>
                512-813-0314
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions, including partnership opportunities, please email us at{" "}
                <a href="mailto:info@ejscconsulting.com">info@ejscconsulting.com</a> or send us a
                message using our contact form.
              </MKTypography>
              {/*<MKBox width="100%" component="form" method="post" autoComplete="off">*/}
              <MKBox width="100%" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      id="name-input"
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      id="email-input"
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      id="message-input"
                      variant="standard"
                      label="How can we help you?"
                      placeholder="Describe your problem, preferably in at least 250 characters"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    id="send-message-button"
                    type="submit"
                    variant="gradient"
                    color="info"
                    onClick={async () => {
                      // 1. validate name is included
                      let nameInput = document.getElementById("name-input");
                      if (!nameInput.value || nameInput.value.trim() === "") {
                        setWarningText("Please include your name");
                        handleClick(nameInput);
                        return;
                      }

                      // 2. validate email is correct + inputted
                      let emailInput = document.getElementById("email-input");
                      if (!emailInput.value || emailInput.value.trim() === "") {
                        setWarningText("Please include a valid email address");
                        handleClick(emailInput);
                        return;
                      }
                      let emailValue = emailInput.value.trim();
                      let isValidEmail = validateEmail(emailValue);
                      if (!isValidEmail) {
                        setWarningText("Please include a valid email address");
                        handleClick(emailInput);
                        return;
                      }

                      // 3. validate message is populated
                      let messageInput = document.getElementById("message-input");
                      if (!messageInput.value || messageInput.value.trim() === "") {
                        setWarningText("Please include your message");
                        handleClick(messageInput);
                        return;
                      }

                      // 4. success
                      await delay(1800);
                      nameInput.value = "";
                      emailInput.value = "";
                      messageInput.value = "";
                      setDisplaySuccess(true);
                    }}
                  >
                    Send Message
                  </MKButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2, fontSize: ".95rem" }}>{warningText}</Typography>
                  </Popover>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <div>{displaySuccess && <JPAlert />}</div>
    </>
  );
}

export default ContactUs;
