export function validateEmail(userEmail) {
  // fucking emails: https://www.regular-expressions.info/email.html
  // this is close enough
  let regex = /^[A-Za-z0-9._%+-]+@[A-Za-z]+\.[A-Za-z]{2,3}$/;
  let result = regex.test(userEmail);
  return result;
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
