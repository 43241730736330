/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/Ethan.jpeg";
import team2 from "assets/images/Caleb2.jpg";
import team3 from "assets/images/Jonathan2.jpg";
import team4 from "assets/images/Sev.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Our Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              We come from a variety of different backgrounds and experiences, but one thing is
              constant:
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              our love of solving challenging problems.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Ethan Ong"
                position={{ color: "info", label: "Salesforce Architect" }}
                description="Prior to entering the software industry, Ethan began his career as an entrepreneur and small business owner. Ethan has a wide breadth of experience and is a certified Salesforce Architect."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Jonathan Satterfield"
                position={{ color: "info", label: "Salesforce Architect" }}
                description="An experienced Salesforce Architect and programming polyglot, Jonathan has experience building business solutions in a variety of technologies ranging from Salesforce to AWS to the original LAMP stack."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Sev Sanchez"
                position={{ color: "info", label: "Salesforce Developer" }}
                description="After serving our country as a Navy veteran, Sev entered the software consulting industry working on large-scale projects with companies such as Accenture, Slalom, and IBM."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Caleb Garcia"
                position={{ color: "info", label: "Salesforce Developer" }}
                description="With a background in working with organizations such as Accenture, Rackspace, and USAA, Caleb has cultivated a deep understanding in how to deliver business value."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
