/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Images
import macbook from "assets/images/macbook.png";

// util
import { validateEmail, delay } from "lib/util";

function Newsletter() {
  const [newsletterSuccessAlertOpen, setNewsletterSuccessAlertOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (currentTarget) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          {/*<Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}></Grid>*/}
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Be the first to see the news</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Your company may not be in the software business, but eventually, software will be in
              your business.
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <MKInput id="newsletter-signup" type="email" label="Email Here..." fullWidth />
              </Grid>
              <Grid item xs={4}>
                <MKButton
                  id="newsletter-signup-button"
                  onClick={async () => {
                    let emailInput = document.getElementById("newsletter-signup");
                    let emailValue = emailInput.value.trim();
                    let isValidEmail = validateEmail(emailValue);
                    if (!isValidEmail) {
                      handleClick(document.getElementById("newsletter-signup-button"));
                    } else {
                      await delay(1800);
                      emailInput.value = "";
                      setNewsletterSuccessAlertOpen(true);
                    }
                  }}
                  variant="gradient"
                  color="info"
                  sx={{ height: "100%" }}
                >
                  Subscribe
                </MKButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 2, fontSize: ".95rem" }}>
                    Please enter a valid email address
                  </Typography>
                </Popover>
              </Grid>
              <div>
                {newsletterSuccessAlertOpen && (
                  <Alert
                    sx={{ fontSize: ".95rem", fontWeight: 400 }}
                    varient="filled"
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                    severity="success"
                    onClose={() => setNewsletterSuccessAlertOpen(false)}
                  >
                    Successfully subscribed to the EJSC newsletter
                  </Alert>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
