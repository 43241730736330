// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "EJSC Consulting LLC",
    image: logoCT,
    route: "/",
  },
  socials: [],
  // socials: [
  //   {
  //     icon: <FacebookIcon />,
  //     link: "https://www.facebook.com/CreativeTim/",
  //   },
  //   {
  //     icon: <TwitterIcon />,
  //     link: "https://twitter.com/creativetim",
  //   },
  //   {
  //     icon: <GitHubIcon />,
  //     link: "https://github.com/creativetimofficial",
  //   },
  //   {
  //     icon: <YouTubeIcon />,
  //     link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
  //   },
  // ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", target: "_self", href: "/about-us" },
        { name: "contact us", target: "_self", href: "/contact-us" },
        // { name: "freebies", href: "https://www.creative-tim.com/templates/free" },
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        // { name: "blog", href: "https://www.creative-tim.com/blog" },
      ],
    },
    {
      name: "address",
      items: [{ name: "PO Box 202562" }, { name: "Austin, TX 78720" }],
    },
    {
      name: "phone",
      items: [{ name: "512-813-0314" }],
    },
    // {
    //   name: "legal",
    //   items: [
    //     { name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
    //     { name: "privacy policy", href: "https://www.creative-tim.com/privacy" },
    //     { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
    //   ],
    // },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date} EJSC Consulting LLC. All rights reserved.
    </MKTypography>
  ),
};
